export default {

    methods: {
        imgSrc(item, size, direction = null){

            if(item == null) return require('@/assets/no-pic.jpg') //return no picture

            if(item.service && item.service == '1') return 'https://i.ytimg.com/vi/'+item.service_id+'/hqdefault.jpg'

            //use tmdb poster files and size configuration
            if(item.external_service == 1 && (item.type == 2 || item.type == 9)) {

                var url = 'https://image.tmdb.org/t/p/'
                /*
                    "w92",
                    "w154",
                    "w185",
                    "w342",
                    "w500",
                    "w780",
                    "original"
                 */
                switch(size){

                    case "lg":
                        return url+'w500'+item.filename

                    case "md":
                        return url+'w342'+item.filename

                    case "sm":
                        return url+'w154'+item.filename

                    case "xs":
                        return url+'w92'+item.filename

                }

                return item.external_filename

            }

            switch(item.type){

                case 1:
                    if(!direction)
                        return 'https://cdn.videospace.fi/'+size+'-'+item.filename
                    else
                        return 'https://cdn.videospace.fi/'+size+'-'+direction+'-'+item.filename

                default:
                    return 'https://cdn.videospace.fi/'+size+'-'+item.filename
            }

        },
    },
}
